// Modal.js
import React, {useEffect, useState} from "react";
import "./ecriture.css";
// import { useDispatch } from "react-redux";
import {toast} from "react-toastify";
//import ButtonP from 'components/buttons/ButtonP';
import { Col, Container, Form, Row} from "react-bootstrap";
import { useCreateEcritureComtableMutation, useDeleteEcritureComtableMutation, useUpdateEcritureComtableMutation } from "actions/sage_clone/ecritures/mutations";
import { useGetEcritureByLivreIdQuery } from "actions/sage_clone/ecritures/queries";
//import Select from "react-select";
import Creatable from 'react-select/creatable';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const Ecriture = ({
  codes,
  codesTiers,
  codeJournalResponse,
  livre_journal,
  facture, 
  setFacture,

}) => {
  const { clientID: id } = useParams();
  const navigate = useNavigate();

  const {data: ecritures,refetch} = useGetEcritureByLivreIdQuery({
    livre_journal: livre_journal,
  })
  console.log("Les ecritures: ", ecritures);
  const customFormatCreateLabel = (inputValue) => `Créer "${inputValue}"`;

  const [ isAlertVisibleDebit, setIsAlertVisibleDebit ] = useState(false);
  const [ isAlertVisibleCredit, setIsAlertVisibleCredit ] = useState(false);
  const [ isAlertVisibleTier, setIsAlertVisibleTier ] = useState(false);

  const [addLivreJournal] = useCreateEcritureComtableMutation()
  const [updateLivreJournal] = useUpdateEcritureComtableMutation()
  const [deleteLivreJournal] = useDeleteEcritureComtableMutation()

  const options = codes.map((code) => {
    return {
      value: parseInt(code.code), label: code.combine, libelle: code.libelle
    }
  })

  /*const optionsTiers = codesTiers.map((code) => {
    return {
      value: parseInt(code.compte_comptable), label: code.compte_comptable + "-" + code.nom
    }
  })*/

  console.log("codes journaux: ",codeJournalResponse,"\nLes codes: ",codes)
  const [mesOptionsTiers,setOptionsTiers] = useState(null)
    const customNoOptionsMessage = () => {
    return <div style={{fontSize:"11px",color:"#ffcc00"}}>{`"Aucun compte tiers n'est associé à ce compte générale"`}</div>
  };


  console.log("Mon tiers: ",mesOptionsTiers)

  useEffect(() => {
    if(facture.compte_general != 0){
      const options = codesTiers.filter((code) => code.compte_generale.includes(facture.compte_general) )
                                .map((code) => {
                                  return {
                                    value: parseInt(code.compte_comptable), label: code.compte_comptable + "-" + code.nom
                                  }
                                })
      setOptionsTiers(options)
      if(typeof options !== 'undefined' && options.length === 0){
        setIsAlertVisibleTier(true);
  
        setTimeout(() => {
          setIsAlertVisibleTier(false);
        }, 3000);
      }
    }

    if(facture.credit>0){
        setIsAlertVisibleDebit(true);

        setTimeout(() => {
            setIsAlertVisibleDebit(false);
        }, 3000);
    }
    if(facture.debit>0){
      setIsAlertVisibleCredit(true);

      setTimeout(() => {
        setIsAlertVisibleCredit(false);
      }, 3000);
    }


  },[facture,setOptionsTiers,])



  // Handle input changes
  const handleInputChange = (event) => {
    const {name, value, type, checked} = event.target;

    setFacture((prevState) => {
      if (type === "checkbox") {
        return {...prevState, [name]: checked};
      }
      else if (name == "date" ) {
        const strValue = value ? value : new Date();
        return {...prevState, [name]: strValue};
      } else if (name == "libelle") {
        const strValue = value ? value : "";
        return {...prevState, [name]: strValue};
      }  else {
        return {...prevState, [name]: parseInt(value, 10)};
      }
    });
  };

  const reset = () => {
    setFacture((prev)=>{ return {...prev,
     compte_general: 0,
     compte_tiers: 0,
     compte_auxiliaire: 0,
     libelle: "",
     date: "",
     debit: 0,
     credit: 0,
     clickRow:false

   }})
 }

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verify all necessary fields are populated.
    const requiredFields = ["libelle"];
    const areFieldsFilled = requiredFields.every(
      (field) => facture[field] && facture[field].toString().trim() !== ""
    );

    if (!areFieldsFilled ) {
      toast.error("Tous les champs sont obligatoires.");
      return;
    }

    // Check for the presence of a file to be uploaded.

      const formData = new FormData();

      // Append scalar fields directly dossier_client
      formData.append("compte_general", facture.compte_general);
      formData.append("compte_auxiliaire", facture.compte_auxiliaire);
      formData.append("compte_tiers", facture.compte_tiers);
      formData.append("libelle", facture.libelle);
      formData.append("debit", facture.debit);
      formData.append("credit", facture.credit);
      formData.append("date", facture.date);
      formData.append("livre_journal", facture.livre_journal);
      formData.append("dossier_client", facture.dossier_client);
      // Perform the POST request
      if((facture.compte_general != facture.compte_auxiliaire) || (facture.compte_auxiliaire!=0 && facture.compte_tiers!=0)){
        try {
          await addLivreJournal({
              livre_journal: facture.livre_journal, 
              libelle: facture.libelle,
              compte_general: facture.compte_general,
              compte_tiers: facture.compte_tiers,
              compte_auxiliaire: facture.compte_auxiliaire,
              debit: facture.debit,
              date: facture.date,
              credit: facture.credit,
              dossier_client: facture.dossier_client,
              formData: formData
          }).unwrap();
          reset();
          toast.success("Ecriture ajoutée avec succès");
          refetch()
        } catch (error) {
          console.error("Requête échouée:", error);
          toast.error(
            `Erreur: Champ requis non remplis ou type de donnée non respecté`
          );
        }
      }
      else{
        if(facture.compte_auxiliaire!=0 && facture.compte_tiers!=0){
          toast.error(
            "Le compte tiers consistut déjà un compte auxiliaire(Tiers)"
          );
        }else {
          try {
            await addLivreJournal({
                livre_journal: facture.livre_journal, 
                libelle: facture.libelle,
                compte_general: facture.compte_general,
                compte_tiers: facture.compte_tiers,
                compte_auxiliaire: facture.compte_auxiliaire,
                debit: facture.debit,
                date: facture.date,
                credit: facture.credit,
                dossier_client: facture.dossier_client,
                formData: formData
            }).unwrap();
            reset();
            toast.success("Ecriture ajoutée avec succès");
            refetch()
          } catch (error) {
            console.error("Requête échouée:", error);
            toast.error(
              `Erreur: Champ requis non remplis ou type de donnée non respecté`
            );
          }finally{
            toast.warning(
              "Attention: Vous n'avez pas auxiliarisé le compte générale "
            );
          }

        }

      }
    
  };

 
  const colourStyles = {
    option: (styles, { data}) => {
      return {
        ...styles,
        fontFamily: data.label,
        fontSize: "12px"
      };
    },

    singleValue: (styles) => ({
      ...styles,
      fontSize: "12px"
    })
  };

  const handleModify = async () => {
    try {
      await updateLivreJournal({
        id: facture.id,
        ...facture,
      }).unwrap();
      toast.success("Ecriture modifiée avec succès");
      reset()
      refetch(); // Refetch client data from the backend
    } catch (error) {
      toast.error(`Erreur de mise à jour: ${error.data?.error || error.message}`);
      console.error('La requête a échoué:', error);
    }
  }

  const handleSuppress = async () => {
    try {
      console.log("ID à supprimer: ",facture.id)
      await deleteLivreJournal({id: facture.id})
      toast.success('Ecriture supprimée avec succès');
      refetch();
    } catch (error) {
      toast.error(`Erreur de suppression`);
    }
  }

  const onChangeTier = (e) => {
    if(e!=null){
      setFacture((prevState) => ({
        ...prevState,
        compte_tiers: e.value || null,
        compte_auxiliaire: 0
      }))
    }
  }

  const handleCreateRole = (e,compte) => {
    if(e!=null){
      if (compte.toString().startsWith("411") ||
          compte.toString().startsWith("412") ||
          compte.toString().startsWith("413") ||
          compte.toString().startsWith("414") ||
          compte.toString().startsWith("416") ||
          compte.toString().startsWith("418") ||
          compte.toString().startsWith("419")
          ){
        return navigate(`/client/${id}/client/`,{state:{comeFrom:true}})
      }
      else if (compte.toString().startsWith("401") ||
               compte.toString().startsWith("402") ||
               compte.toString().startsWith("404") ||
               compte.toString().startsWith("408") ||
               compte.toString().startsWith("409")
              ){
        return navigate(`/client/${id}/fournisseurs/`, {state:{comeFrom:true}})
      }
      else if (compte.toString().startsWith("421") || 
               compte.toString().startsWith("423") || 
               compte.toString().startsWith("424") ||
               compte.toString().startsWith("425") ||
               compte.toString().startsWith("426") ||
               compte.toString().startsWith("428") 
              ){
        return navigate(`/client/${id}/salaries/`, {state:{comeFrom:true}})
      }
    }
    
  }

  return (
    <div >
        <div className="container-background w-100 w-md-75 px-0 ">
            <Container fluid className="container-style p-4 mb-5">
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="d-flex flex-column align-items-center gap-2" >
                  <h2>Saisir une écriture comptable</h2>
                <div className="w-100 d-flex flex-column gap-3">
                    <Row className="row-gap-2 w-100" style={{justifyContent:"center"}}>
                        <Col sm={2}>
                            <Form.Group controlId="formFournisseur">
                            <Form.Label className="mb-2">Date*</Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={facture.date}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                            />
                            </Form.Group>
                        </Col>
   
                        <Col sm={2}>
                            <Form.Group controlId="formFournisseur">
                            <Form.Label className="mb-2">Compte Général*</Form.Label>
                            <Creatable 
                              options={options} 
                              onChange={(e) => setFacture((prevState) => ({
                                ...prevState,
                                compte_general: e.value,
                                libelle: e.libelle,
                                compte_auxiliaire: e.value,

                              }))}
                              
                              //styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              styles={colourStyles}
                            />

                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group controlId="formFournisseur">
                            <Form.Label className="mb-2">Auxiliaire P/C</Form.Label>
                            <Form.Control
                                type="number"
                                name="compte_auxiliaire"
                                value={facture.compte_auxiliaire}
                                onChange={handleInputChange}
                                className="rounded rounded-pill hide"
                                disabled={facture.compte_auxiliaire==0?true:false}
                            />

                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2 fs">Auxiliaire Tiers</Form.Label>
                            {/*<Creatable 
                              options={mesOptionsTiers} 
                              name="tiers"
                              formatCreateLabel={customFormatCreateLabel}
                              onChange={(e) => onChangeTier(e)}
                              
                              styles={colourStyles}
                              isDisabled={facture.compte_general!=0?false:true}
                              noOptionsMessage={customNoOptionsMessage}
                              isClearable={true}
                            />*/}
                            <Creatable 
                              options={mesOptionsTiers} 
                              isClearable 
                              name="tiers"
                              formatCreateLabel={customFormatCreateLabel}
                              onChange={(e) => onChangeTier(e)}
                              isDisabled={facture.compte_general!=0?false:true}
                              styles={colourStyles}
                              noOptionsMessage={customNoOptionsMessage}
                              onCreateOption={(e) => handleCreateRole(e,facture.compte_auxiliaire)}
                            />
                            {isAlertVisibleTier?<div style={{backgroundColor:"#ffcc00",fontSize:"10px",padding:"5px",borderRadius:"8px",marginTop:"5px"}}>Aucun compte tiers n&apos;est associable à ce compte générale </div>:<></>}

                            </Form.Group>
                        </Col>
           
                        <Col sm={2}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Libellé*</Form.Label>
                            <Form.Control
                                type="text"
                                name="libelle"
                                value={facture.libelle}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                                style={{fontSize:"11px", padding:"10px 5px"}}
                            />
                            </Form.Group>
                        </Col>
        
                        <Col sm={1}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Débit</Form.Label>
                            <Form.Control
                                type="number"
                                name="debit"
                                value={facture.debit}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                                disabled={facture.debit==0&&facture.credit>0?true:false}
                            />
                            {facture.debit==0&&facture.credit>0&&isAlertVisibleDebit?<div style={{backgroundColor:"#ffcc00",fontSize:"10px",padding:"5px",borderRadius:"8px",marginTop:"5px"}}>Débit désactivé car le crédit est différent de zéro</div>:<></>}
                            </Form.Group>
                        </Col>
                        <Col sm={1}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Crédit</Form.Label>
                            <Form.Control
                                type="number"
                                name="credit"
                                value={facture.credit}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                                disabled={facture.credit==0&&facture.debit>0?true:false}
                            />
                            {facture.credit==0&&facture.debit>0&&isAlertVisibleCredit?
                              <div style={{
                                    backgroundColor:"#ffcc00",
                                    fontSize:"10px",
                                    padding:"5px",
                                    borderRadius:"8px",
                                    marginTop:"5px"}}>
                                Crédit désactivé car le débit est différent de zéro
                              </div>:<></>}

                            </Form.Group>
                        </Col>
                      </Row>
                      <Row className="row-gap-2 w-100" style={{justifyContent:"center"}}>
                        <Col sm={6}>
                          <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                          {!facture.clickRow&&
                              <button
                                type="submit"
                                className="btn btn-primary rounded rounded-pill px-4"
                                style={{
                                    width:"200px",
                                    backgroundColor: "#068f7a",
                                    borderColor: "#068f7a",
                                }}
                                disabled={
                                    !facture.libelle || !facture.date 
                                } // Add other fields as necessary
                              >
                                Ajouter
                              </button>
                          }
                          </div>
                          

                        </Col>
                      </Row>

                    {/* ... Other input fields ... */}

                </div>
                </div>
                {/* ... Other input fields ... */}
            </Form>
             <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",marginTop:"20px",marginLeft:"-10px"}}>
              {facture.clickRow&&
                <button
                onClick={() => handleModify()}
                className="btn btn-primary rounded rounded-pill px-4"     
                style={{
                    backgroundColor: "#ffbf00",
                    borderColor: "#daa520",
                    width:"200px",
                }}
                disabled={
                    !facture.libelle || !facture.date 
                } // Add other fields as necessary
              >
                Modifier
              </button>
              
              
              }
                

                <button
                  onClick={() => handleSuppress()}
                  className="btn btn-primary rounded rounded-pill px-4"
                  style={{
                      backgroundColor: "#c21807",
                      borderColor: "#7c0a02",
                      
                      width:"200px",
                  }}
                  disabled={
                      !facture.libelle || !facture.date 
                  } // Add other fields as necessary
                >
                  Suppression
                </button>
            </div>

            </Container>
        </div>
    </div>
  );
};


export default Ecriture;
