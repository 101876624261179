import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useExtractOcrDataQuery, useGetOcrFactureQuery, useLazyExtractOcrDataQuery } from 'actions/orc/queries';
import { DEFAULT_API_IMAGE } from 'routes/api/endpoints';
//import CustomBootstrapTable from 'utils/reactTable';
import ButtonGreen from 'components/buttons/ButtonGreen';
import { toast } from 'react-toastify';
import left from "../../image/arrow-left.png";

//***************************************Pour l'écriture comptable***********************************************************//
import { FormProvider, useForm } from "react-hook-form";
import { useGetCodesQuery } from "actions/comptabilite/queries";
import { useGetCodeJournalQuery } from "actions/sage_clone/code_journaux/queries";
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/userSliceWithTokenValidation'; // Adjust according to your project structure
import useJournalData from "./useJournalData";
import ModalJournal from "./ModalJournal";
//***************************************END(Pour l'écriture comptable)******************************************************//

function ViewInvoice() {
    //////////////////////////////////////////Pour les écritures comptables ///////////////////////////////////////////////////
    const { clientID: dossier_id } = useParams();
    const { updatedJournal, refetch } = useJournalData(dossier_id);
    const { data: codesResponse = [], isLoading: isLoadingResponse } = useGetCodesQuery({ dossier_client: dossier_id });// L'objectif est de recupérer le plan comptable associé à un dossier
    const userStatus = useSelector(getUser);
    const {
        data: codeJournalResponse = [],
        isLoading: isLoadingcodeJournals,
    } = useGetCodeJournalQuery({ comptable: userStatus?.id });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDossierCreated = () => {
        setIsModalOpen(false);
      };

    const closeModal = () => {
        setIsModalOpen(false);

    };
    const openModal = () => {
        setIsModalOpen(true);
        refetch()
      };
    

    if (!isLoadingResponse) console.log("Mes codes ordered: ", codesResponse);
    if (!isLoadingcodeJournals) console.log("Mes codes journaux: ", codeJournalResponse.data);
    ////////////////////////////////////END(Pour les écritures comptables)///////////////////////////////////////////////////
    
    const location = useLocation();
    const navigate = useNavigate();
    const { ocrId, dataFromOCR, fileUrl: locationFileUrl } = location.state || {};
    const { id } = useParams();

    const [ocrResult, setOcrResult] = useState(null); // OCR result data
    const [fileUrl, setFileUrl] = useState(locationFileUrl || null);

    const { data: factures, isLoading: isFacturesLoading } = useGetOcrFactureQuery(dossier_id);
    const { data: ocrData, isLoading, isError, error } = useExtractOcrDataQuery(ocrId, {
        skip: !ocrId,
    });
    const [triggerExtractOcrData, { isLoading: isExtracting }] = useLazyExtractOcrDataQuery();

//************************************** Nouveaux élements pour le tableau ******************************************* */    
    const [items, setItems] = useState([
        { id: 1, description: '', quantity: 0, price: 0, taxe: 0, amount: 0, amount_taxe: 0, amount_ttc: 0 }
    ]);

    const tableStyles = {
        container: {
            width: '100%',
            overflowX: 'auto',
            padding: '20px',
            fontFamily: 'Arial, sans-serif'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginBottom: '20px',
            backgroundColor: '#fff'
        },
        th: {
            backgroundColor: '#f8f9fa',
            padding: '12px',
            textAlign: 'left',
            borderBottom: '2px solid #dee2e6',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#333'
        },
        td: {
            padding: '12px',
            borderBottom: '1px solid #dee2e6',
            fontSize: '14px'
        },
        input: {
            width: '100%',
            padding: '8px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '14px',
            outline: 'none'
        },
        numberInput: {
            width: '100px',
            padding: '8px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '14px',
            outline: 'none'
        },
        addButton: {
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
            marginTop: '10px'
        },
        addEcriture: {
            backgroundColor: '#068f7a',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
            marginTop: '10px',
            marginLeft: '20px'
        },
        deleteButton: {
            backgroundColor: '#ff4444',
            color: 'white',
            padding: '6px 12px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px'
        },
        totalRow: {
            textAlign: 'right',
            padding: '20px 0',
            fontWeight: 'bold',
            fontSize: '16px'
        }
    };

    const handleChange = (id, field, value) => {
        setItems(prevItems => prevItems.map(item => {
            if (item.id === id) {
                const updatedItem = { ...item, [field]: value };
                if (field === 'quantity' || field === 'price') {
                    updatedItem.amount = (updatedItem.quantity * updatedItem.price).toFixed(2);
                }
                return updatedItem;
            }
            return item;
        }));
    };

    const addRow = () => {
        const newId = items.length > 0 ? Math.max(...items.map(item => item.id)) + 1 : 1;
        setItems([...items, { id: newId, description: '', quantity: 0, price: 0, amount: 0 }]);
    };

    const deleteRow = (id) => {
        if (items.length > 1) {
            setItems(items.filter(item => item.id !== id));
        }
    };
//************************************** End Nouveaux élements pour le tableau ******************************************* */    

    useEffect(() => {
        if(dataFromOCR){
            const newItems_sans_ttc = transformArraysToDictionaries(dataFromOCR);
            const newItems = calcul_rate(newItems_sans_ttc)
            setItems(newItems);
        }
        
        if (id && factures) {
            const facture = factures?.data?.find((f) => f.id === parseInt(id));
            
            if (facture) {
                setFileUrl(facture.file);
                console.log(dataFromOCR);
                
            } /*else {
                toast.error('Facture introuvable.');
            }*/
        }
    }, [id, factures]);

    // Handle OCR execution
    const handleExecuteOcr = async () => {
        if (!fileUrl) {
            toast.warning('Aucune image disponible pour l\'extraction.');
            return;
        }

        try {
            const ocrResponse = await triggerExtractOcrData(id || ocrId).unwrap();
            setOcrResult(ocrResponse);
            toast.success('Extraction OCR réussie.');
            const newItems = transformArraysToDictionaries(ocrResponse);
            setItems(newItems);
            console.log("Response New Items",newItems);
            
        } catch (err) {
            toast.error('Erreur lors de l\'extraction OCR.');
            console.error(err);
        }
    };

    // Fonction pour transformer les sous-tableaux en dictionnaires
    const transformArraysToDictionaries = (arrays) => {
        return arrays["items"].map((array, index) => {
            return {
                id: index + 1,
                description: array.description || '',
                quantity: array.quantity || 0,
                price: array?.price || array?.unit_price || array?.total_ht || array?.total_tva || array?.subtotal_tva  || array?.subtotal_ht || 0,
                taxe: array?.taxe || array?.vat_rate || array?.rate || 0,
                amount: array?.amount || array?.total || array?.total_ttc || 0,
            };
        });
    };

    const calcul_rate = (arrays) => {
        return arrays.map((array,index) => {
            return {
                id: index + 1,
                description: array.description,
                quantity: array.quantity,
                price: array.price ,
                taxe: array.taxe,
                amount: array.amount,
                amount_taxe: (array.amount * array.taxe)/100,
                amount_ttc: array.amount * (1+array.taxe) / 100
            }
        })
    }
    // Process the OCR data
    const processedData = (ocrResult && processOcrData(ocrResult)) || (ocrData && processOcrData(ocrData)) || (dataFromOCR && processOcrData(dataFromOCR)) ||{
        lineItems: [],
        columns: [
            {
                dataField: 'description',
                text: 'Description',
            },
            {
                dataField: 'quantity',
                text: 'Quantité',
            },
            {
                dataField: 'price',
                text: 'Prix Unitaire',
            },
            {
                dataField: 'amount',
                text: 'Prix Total',
            },
        ],
        invoiceRef: '',
        invoiceDate: '',
        totalAmount: '',
    };
    /*if(processedData.lineItems){
        setItems(processedData?.lineItems)
    }  */ 
    

    if (isFacturesLoading || isLoading) return <p>Chargement...</p>;
    if (isError) return <p>Erreur : {error?.data?.message || error.error}</p>;

    
    const methods = useForm();
    return (
        <div style={{ padding: '20px' }}>
            <div
                style={{cursor: "pointer", width: "fit-content"}}
                onClick={() => navigate(-1)}>
                <img
                src={left}
                className="imageLeft"
                style={{width: "10px", height: "14px"}}
                />
                <span style={cardStyle} className="mx-2">
                Retour
                </span>
            </div>
            <h1>Détails de la Facture</h1>
            {/* Display invoice info */}
            <div style={{ marginBottom: '20px' }}>
                {processedData.invoiceRef && <p><strong>Référence Facture:</strong> {processedData.invoiceRef}</p>}
                {processedData.invoiceDate && <p><strong>Date Facture:</strong> {processedData.invoiceDate}</p>}
            </div>
            {/* Display the image */}
            {fileUrl && (
                <div style={{ marginBottom: '20px' }}>
                    <img
                        src={id ? fileUrl : DEFAULT_API_IMAGE + fileUrl}
                        alt="Facture"
                        style={{ maxWidth: '100%', marginBottom: '20px', opacity: isExtracting ? 0.5 : 1 }}
                    />
                    {/*<iframe src={fileUrl} style={{ maxWidth: '100%', marginBottom: '20px', opacity: isExtracting ? 0.5 : 1 }}/>*/}
                    {id && (
                        <ButtonGreen
                            text={isExtracting ? 'Extraction en cours...' : 'Extraire OCR'}
                            onClick={handleExecuteOcr}
                            disabled={isExtracting}
                        />
                    )}
                </div>
            )}
            {/* Display the invoice table */}
            {/*{processedData.columns?.length > 0 ? (
                <CustomBootstrapTable
                    keyField="id"
                    data={processedData.lineItems}
                    columns={processedData.columns}
                    wrapperClasses="table-wrapper"
                />
            ) : (
                <p>Aucune donnée disponible pour afficher.</p>
            )}
            {/* Display the total amount */}
{/***************************************** struture de la table ****************************************************/}      

        <div style={tableStyles.container}>
            <table style={tableStyles.table}>
                <thead>
                    <tr>
                        <th style={tableStyles.th}>Description</th>
                        <th style={tableStyles.th}>Quantité</th>
                        <th style={tableStyles.th}>Prix Unitaire</th>
                        <th style={tableStyles.th}>Taxe(%)</th>
                        <th style={tableStyles.th}>Prix Hors Taxe</th>
                        <th style={tableStyles.th}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => (
                        <tr key={item.id}>
                            <td style={tableStyles.td}>
                                <input
                                    type="text"
                                    value={item.description}
                                    onChange={(e) => handleChange(item.id, 'description', e.target.value)}
                                    style={tableStyles.input}
                                    placeholder="Description"
                                />
                            </td>
                            <td style={tableStyles.td}>
                                <input
                                    type="text"
                                    value={item.quantity}
                                    onChange={(e) => handleChange(item.id, 'quantity', e.target.value)}
                                    style={tableStyles.numberInput}
                                    min="0"
                                />
                            </td>
                            <td style={tableStyles.td}>
                                <input
                                    type="text"
                                    value={item.price}
                                    onChange={(e) => handleChange(item.id, 'price', e.target.value)}
                                    style={tableStyles.numberInput}
                                    min="0"
                                />
                            </td>
                            <td style={tableStyles.td}>
                                <input
                                    type="text"
                                    value={item.taxe}
                                    onChange={(e) => handleChange(item.id, 'taxe', e.target.value)}
                                    style={tableStyles.numberInput}
                                    min="0"
                                />
                            </td>
                            <td style={tableStyles.td}>
                                <input
                                    type="text"
                                    value={item.amount}
                                    onChange={(e) => handleChange(item.id, 'amount', e.target.value)}
                                    style={tableStyles.numberInput}
                                    min="0"
                                />
                            </td>
                            {/*<td style={tableStyles.td}>
                                {parseFloat(item.amount).toLocaleString('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}
                            </td>*/}
                            <td style={tableStyles.td}>
                                <button
                                    onClick={() => deleteRow(item.id)}
                                    style={tableStyles.deleteButton}
                                >
                                    Supprimer
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>
                <button
                    onClick={addRow}
                    style={tableStyles.addButton}
                >
                    Ajouter une ligne
                </button>
                
                <button
                    onClick={openModal}
                    style={tableStyles.addEcriture}
                >
                    Créer des écritures comptables
                </button>
            </div>

            <div style={tableStyles.totalRow}>
                Total Général: {' '}
                {items.reduce((sum, item) => sum + parseFloat(item.amount), 0)
                    .toLocaleString('fr-FR', {
                        style: 'currency',
                        currency: 'EUR'
                    })}
            </div>
        </div>

{/***************************************** end ofstruture de la table **********************************************/}    
            {processedData.totalAmount && (
                <div style={{ marginTop: '20px', textAlign: 'right' }}>
                    <p><strong>Total:</strong> {processedData.totalAmount} €</p>
                </div>
            )}
{/**********************************************************Modal Ecriture Comptable************************************************ */}
            {isModalOpen && (
            <FormProvider {...methods}>
                <ModalJournal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    onDossierCreated={handleDossierCreated}
                    journals={updatedJournal}
                    codes={codesResponse || []}
                    codeJournalResponse={codeJournalResponse.data || []}
                    refetch={refetch}
                    dossier_id={dossier_id}
                    ecritures={items}
                >
                <h2
                    style={{ color: " #009688", fontSize: "20px", fontWeight: 700 }}>
                    <span>
                    {" "}
                    <img src={left} />
                    </span>{" "}
                    &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
                </h2>
                </ModalJournal>
            </FormProvider>
            )}
{/**********************************************************END(Modal Ecriture Comptable)************************************************ */}

        </div>
    );
}

export default ViewInvoice;

const cardStyle = {
    color: "#009688",
    fontWeight: "700",
    fontSize: "15px",
    fontFamily: "Montserrat",
  };

// Function to process OCR data
function processOcrData(ocrData) {
    let headers = [];
    let headerRowIndex = -1;
    let foundHeader = false;
    let lineItems = [];
    let invoiceRef = '';
    let invoiceDate = '';
    let totalAmount = '';
    const totalRegex = /total\s*(t\.?t\.?c\.?)?\s*[:-]?\s*(\d+[,.]?\d*)\s*€?/i;

    for (let i = 0; i < ocrData?.length; i++) {
        const row = ocrData[i];
        const rowText = row.join(' ').toLowerCase();

        if (rowText.includes('réf. facture')) {
            invoiceRef = row.join(' ').replace(/réf\. facture[:-]?/i, '').trim();
            continue;
        }

        if (rowText.includes('date facture')) {
            invoiceDate = row.join(' ').replace(/date facture[:-]?/i, '').trim();
            continue;
        }

        const totalMatch = rowText.match(totalRegex);
        if (totalMatch) {
            totalAmount = totalMatch[2].replace(',', '.');
            continue;
        }

        if (!foundHeader) {
            const headerTerms = ['description', 'désignation', 'quantité', 'prix unitaire', 'prix total', 'total h.t'];
            const headerFound = headerTerms.some(term => rowText.includes(term));
            if (headerFound) {
                headers = row;
                headerRowIndex = i;
                foundHeader = true;
                break;
            }
        }
    }

    if (!foundHeader) {
        return { lineItems: [], columns: [], invoiceRef, invoiceDate, totalAmount };
    }

    const columnMap = {};
    headers.forEach((header, index) => {
        const headerText = header.toLowerCase();
        if (headerText.includes('description') || headerText.includes('désignation')) {
            columnMap.description = index;
        } else if (headerText.includes('quantité')) {
            columnMap.quantity = index;
        } else if (headerText.includes('prix unitaire')) {
            columnMap.price = index;
        } else if (headerText.includes('prix total') || headerText.includes('total h.t')) {
            columnMap.amount = index;
        }
    });

    const columns = [
        {
            dataField: 'description',
            text: 'Description',
        },
        {
            dataField: 'quantity',
            text: 'Quantité',
        },
        {
            dataField: 'price',
            text: 'Prix Unitaire',
        },
        {
            dataField: 'amount',
            text: 'Prix Total',
        },
    ];

    for (let i = headerRowIndex + 1; i < ocrData.length; i++) {
        const row = ocrData[i];
        if (row.length === 0) continue;

        const lineItem = {};
        for (const [key, index] of Object.entries(columnMap)) {
            lineItem[key] = row[index] || '';
        }

        if (!lineItem.description && lineItems.length > 0) {
            lineItems[lineItems.length - 1].description += ' ' + row.join(' ');
            continue;
        }

        lineItem.id = i;
        lineItems.push(lineItem);
    }

    if (!totalAmount) {
        let total = 0;
        lineItems.forEach(item => {
            let price = parseFloat(
                item.amount?.replace(',', '.').replace(/[^0-9.]/g, '') || 0
            );
            total += price;
        });
        totalAmount = total.toFixed(2);
    }

    return { lineItems, columns, invoiceRef, invoiceDate, totalAmount };
}
