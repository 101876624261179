import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AGEE, AUXILIAIRE, GENERALE } from 'routes/navigation/navigationPaths';
import HeaderFirst from 'utils/HeaderFirst'


export default function Balance() {

  const navigate = useNavigate()

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Balance Comptable: <span style={{color:"#009688"}}>Générale</span>
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />

      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"10px"}}>
        <div>
          <button onClick={() => navigate(GENERALE)}>Balance Générale</button>
        </div>
        <div>
          <button onClick={() => navigate(AUXILIAIRE)}>Balance Auxiliaire</button>
        </div>
        <div>
          <button onClick={() => navigate(AGEE)}>Balance Agée</button>
        </div>
      </div>


    </div>
  )
}
