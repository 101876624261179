import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import HeaderFirst from 'utils/HeaderFirst';
import HeaderButton from 'utils/headerButton';
import CustomBootstrapTable from 'utils/reactTable';
import ModalClient from './Modal';
import arrow from '../../image/arrow-left.png';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './client.css';
import TablePaginators from 'components/Pagination/TablePaginator';
import useClientData from './useClientData';
import { toast } from 'react-toastify';
import ModalExcel from './ModalExcel/ModalExcel';
import { HiCloudUpload } from 'react-icons/hi';
import { usePermissions } from 'hooks/usePermissions';
import DownloadExcel from 'pagesSAGECOMPTA/FileExcel/FileExcel';
import ButtonWhite from 'components/buttons/ButtonWhite';
//import { HiCloudUpload } from "react-icons/hi";

// import axios from 'axios';
// import { useGetClientQuery } from 'actions/dossiers/queries';

const SalariePage = () => {
  const { clientID: dossier_id } = useParams();
  const [serachTerm, setSearchedTerm] = useState('');
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const { updatedClients, handleUpdateClient, handleDeleteClient, refetch } = useClientData(dossier_id);
  const [viewArchived, setViewArchived] = useState(false);
  const data_from_ecriture_comptable = useLocation().state?.comeFrom || false

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false)

  const filteredData = updatedClients
    .filter((client) => (viewArchived ? !client.active : client.active))
    .filter((client) =>
      client?.nom?.toLowerCase().includes(serachTerm.toLowerCase())
    );

  const navigate = useNavigate();
  const permissions = usePermissions();

  const currentPageData = filteredData.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const handleActionSelected = (action) => {
    if (action === 'archive') {
      setViewArchived(!viewArchived); // Toggle between viewing archived and active depenses
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchedTerm(newSearchTerm);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const rowEvents = {
    onClick: (e, row) => {
      console.log('Table row clicked:', row); // Check if this logs
      handleRowClick(row);
    },
  };

  const columns = [
    {
      dataField: 'compte_comptable',
      text: 'N°Compte',
      formatter: (cell) => <div>{cell}</div>,
    },
    {
      dataField: 'nom',
      text: 'Nom',
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: 'created_at',
      text: "Date d'ajout",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: 'telephone',
      text: 'Numéro de téléphone',
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: 'email',
      text: 'E-mail',
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: 'fonction',
      text: 'Fonction',
      formatter: (cell) => <div>{cell}</div>,
    },
  ];

  const handleDossierCreated = () => {
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalFileOpen(false)

  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsModalFileOpen(false);
  };
  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Salariés</h4>
      <span className="nbrDossier">{filteredData?.length}</span>
    </div>
  );
  const description = <div>Voici tous vos salariés</div>;

  const handleRowClick = (row) => {
    const clickedRowId = row?.id;
    console.log(clickedRowId);
  };

  const onEyesClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const detailClientURL = `details/${selectedRowId}/`;
      navigate(detailClientURL);
      console.log(selectedRowId);
    }
  };

  const onClickDelete = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const client = updatedClients.find((d) => d.id === selectedRowId);
      if (!client) {
        toast.error('Salarié non trouvé.');
        return;
      }

      try {
        const newArchivedStatus = !client.active;
        await handleDeleteClient(selectedRowId)
        /*await handleUpdateClient(selectedRowId, {
          active: newArchivedStatus,
          archived: true
        });*/
        toast.info(
          `Salarié ${newArchivedStatus ? 'archivé' : 'désarchivé'} avec succès.`
        );
      } catch (error) {
        toast.error(
          'Une erreur est survenue lors de la mise à jour du salarié.'
        );
        console.error('Erreur lors de la mise à jour du salarié :', error);
      }
    } else {
      toast.warning(
        'Veuillez sélectionner exactement un salarié à archiver ou désarchiver.'
      );
    }
  }

  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const client = updatedClients.find((d) => d.id === selectedRowId);
      if (!client) {
        toast.error('Salarié non trouvé.');
        return;
      }

      try {
        const newArchivedStatus = !client.active;
        await handleUpdateClient(selectedRowId, {
          active: newArchivedStatus,
          archived: true
        });
        toast.info(
          `Salarié ${newArchivedStatus ? 'archivé' : 'désarchivé'} avec succès.`
        );
      } catch (error) {
        toast.error(
          'Une erreur est survenue lors de la mise à jour du salarié.'
        );
        console.error('Erreur lors de la mise à jour du salarié :', error);
      }
    } else {
      toast.warning(
        'Veuillez sélectionner exactement un salarié à archiver ou désarchiver.'
      );
    }
  };

  const onActivedClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const client = updatedClients.find((d) => d.id === selectedRowId);
      if (!client) {
        toast.error('Salarié non trouvé.');
        return;
      }

      try {
        const newActiveStatus = !client.active;
        await handleUpdateClient(selectedRowId, {
          active: newActiveStatus,
        });
        toast.info(
          `Salarié ${newActiveStatus ? 'activé' : 'désactivé'} avec succès.`
        );
      } catch (error) {
        toast.error(
          'Une erreur est survenue lors de la mise à jour du salarié.'
        );
        console.error('Erreur lors de la mise à jour du salarié :', error);
      }
    } else {
      toast.warning(
        'Veuillez sélectionner exactement un client à activer ou désactiver.'
      );
    }
  };

  const methods = useForm();
  return (
    <div
      className="global"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <div
        className="header pb-3"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
          // padding: "13px",
        }}
      >
        <HeaderFirst nom={nom} description={description} />
        {permissions.MANAGE_EMPLOYEES && (
          <>
            <div>
              <ButtonWhite
                text={
                  <span style={ajoutFou}><HiCloudUpload className='fs-6' /> Importer des salariés</span>
                }
                onClick={() => { setIsModalFileOpen(true); setIsModalOpen(false) }} />
            </div>
            <div>
              <ButtonWhite
                text="+ Ajouter un salarié"
                onClick={openModal} />
            </div>
            <DownloadExcel />
          </>
        )}
        {isModalFileOpen && (
          <FormProvider {...methods}>
            <ModalExcel
              isOpen={isModalFileOpen}
              onClose={closeModal}
              refetch={refetch}
              retour={data_from_ecriture_comptable}
            >
              <h2
                style={{ color: ' #009688', fontSize: '20px', fontWeight: 700 }}
              >
                <span>
                  {' '}
                  <img src={arrow} />
                </span>{' '}
                &nbsp;IMPORTER VOS FICHIERS CLIENTS
              </h2>
            </ModalExcel>
          </FormProvider>

        )}


        {isModalOpen && (
          <FormProvider {...methods}>
            <ModalClient
              isOpen={isModalOpen}
              onClose={closeModal}
              onDossierCreated={handleDossierCreated}
              clients={updatedClients}
              refetch={refetch}
              retour={data_from_ecriture_comptable}
            >
              <h2
                style={{ color: ' #009688', fontSize: '20px', fontWeight: 700 }}
              >
                <span>
                  {' '}
                  <img src={arrow} />
                </span>{' '}
                &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
              </h2>
            </ModalClient>
          </FormProvider>
        )}

      </div>
      {permissions.MANAGE_EMPLOYEES && (
        <HeaderButton
          placeholder={'Rechercher un client'}
          onSearchChange={handleSearchChange}
          isButtonsEnabled={selectedRows.length > 0}
          onClickArchive={onClickArchive}
          onActivedClick={onActivedClick}
          onEyesClick={onEyesClick}
          onClickDelete={onClickDelete}
        />
      )}

      <div>
        {updatedClients ? (
          <CustomBootstrapTable
            keyField="id"
            data={currentPageData?.filter((client) =>
              client.nom.toLowerCase().includes(serachTerm.toLowerCase())
            )}
            columns={columns}
            selectedRows={selectedRows}
            onSelectRow={handleRowSelect}
            wrapperClasses="table-wrapper"
            rowEvents={rowEvents}
          />
        ) : (
          <p>Chargement des données...</p>
        )}
      </div>
      {currentPageData && currentPageData.length > 0 && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredData.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
};

const ajoutFou = {
  fontFamily: 'Montserrat',
  fontWeight: '600',
  fontSize: '13px',
};
export default SalariePage;
