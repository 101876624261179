// Modal.js
import React, {useState} from "react";
import "../../components/Modal.css";
import {useCreateClientMutation} from "actions/clients/mutations";
// import { useDispatch } from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {useGetCodesClientsQuery} from "actions/comptabilite/queries";
import CreatableSelect from "react-select/creatable";
import Switch from "components/SwitchButton/Switch";
import GeneriqueModal from "components/GeneriqueModal";
import ButtonGreen from "components/buttons/ButtonGreen";
import ButtonCancel from "components/buttons/ButtonCancel";

const ModalClient = ({isOpen, onClose, onDossierCreated, retour}) => {
  const {clientID: id} = useParams();
  const {data: codes = []} = useGetCodesClientsQuery();
  const [creationClient, {isLoading}] = useCreateClientMutation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useFormContext();

  const [lettrage, setLettrage] = useState(false);

  const onSubmit = (data) => {
    const clientData = {
      ...data,
      dossier_id: id,
      lettrage: lettrage,
      compte_comptable: data.compte_comptable?.value.toString() || null,
    };
    creationClient(clientData)
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success("Création client réussi !");
        reset();
        onDossierCreated();
        if(retour) navigate(-1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const options = codes?.map((code_client) => ({
    value: code_client.code,
    label: code_client.combine,
  }));

  return (
    <>
      <GeneriqueModal
        className="modal-overlay"
        onClose={onClose}
        isOpen={isOpen}
        title="Ajouter un client">
        <form
          className="fournisseur-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="nom" className="form-label">
              Prénom et nom
            </label>
            <input
              className="form-controll"
              type="text"
              id="nom"
              placeholder="Prénom et nom"
              {...register("nom", {required: "Nom obligatoire"})}
            />
            {errors.nom && (
              <div className="text-danger">{errors.nom.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="telephone" className="form-label">
              Numéro de téléphone
            </label>
            <input
              className="form-controll"
              type="tel"
              id="telephone"
              name="telephone"
              placeholder="777777777"
              {...register("telephone", {
                required: "Téléphone obligatoire de 08 caractères",
                maxLength: 9,
              })}
            />
            {errors.telephone && (
              <div className="text-danger">{errors.telephone.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="adresse" className="form-label">
              Adresse
            </label>
            <input
              className="form-controll"
              type="text"
              id="adresse"
              placeholder="Adresse"
              {...register("adresse")}
            />
            {errors.adresse && (
              <div className="text-danger">{errors.adresse.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              className="form-controll"
              type="email"
              id="email"
              placeholder="email@example.com"
              {...register("email", {required: "Email obligatoire"})}
            />
            {errors.email && (
              <div className="text-danger">{errors.email.message}</div>
            )}
          </div>

          {/*<div className="form-group">
                <label htmlFor="compte_comptable" className="form-label">
                  Compte Comptable
                </label>
                <input
                  className="form-controll"
                  type="number"
                  id="compte_comptable"
                  name="compte_comptable"
                  placeholder="Compte client commence par 411..."
                  {...register('compte_comptable', { required: 'Compte comptable obligatoire', minLength:4})}
                />
 
                {errorCompte != "" && (
                  <div className="text-danger">
                    {errorCompte}
                  </div>
                )}
                {errors.compteComptable && (
                  <div className="text-danger">
                    {errors.compteComptable.message}
                  </div>
                )}
              </div>*/}
          <div className="form-group">
            <label htmlFor="compte_comptable" className="form-label">
              Compte Comptable
            </label>
            <Controller
              control={control}
              name="compte_comptable"
              rules={{required: "Compte comptable obligatoire"}}
              render={({field}) => (
                <CreatableSelect
                  {...field}
                  options={options}
                  placeholder="Sélectionner ou créer un compte client"
                  isClearable
                />
              )}
            />
            {errors.compte_comptable && (
              <div className="text-danger">
                {errors.compte_comptable.message}
              </div>
            )}
          </div>

          {/* Lettrage Checkbox */}
          <div className="form-group d-flex align-items-end gap-3">
            <label htmlFor="lettrage" className="form-label">
              Lettrage
            </label>
            <Switch
              isOn={lettrage}
              handleToggle={() => setLettrage(!lettrage)}
              colorOne="#068f7a"
              colorTwo="#ccc"
            />
          </div>

          <div className="modal-footer">
            <ButtonCancel text={"Annuler"} onClick={onClose} />
            <ButtonGreen
              text={isLoading ? "Chargement..." : "Enregistrer"}
              type={"submit"}
            />
          </div>
        </form>
      </GeneriqueModal>
    </>
  );
};

export default ModalClient;
