// Modal.js
import React, { useState } from 'react';
import './Modal.css';
import { Form, Container, Row, Col} from 'react-bootstrap';
import { useAddFileSalariesMutation } from 'actions/importer_fichier/mutations';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import FileUpload from './File/FileUpload';


const ModalExcel = ({ isOpen, onClose,refetch, retour }) => {
  const { clientID: id } = useParams();
  // fichier joint
  const [selectedFile, setSelectedFile] = useState(null);
  const [selected,setSelected] = useState(false)
  const navigate = useNavigate();

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setSelected(true)
  };

  const [addFile] = useAddFileSalariesMutation()
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for the presence of a file to be uploaded.
    if (selectedFile) {
      const formData = new FormData();

      formData.append('file', selectedFile);
      formData.append('dossier_id', id);
      // Perform the POST request
      try {
        await addFile({dossier:id,formData:formData}).unwrap();
        toast.success('Fichier ajouté avec succès');
        onClose()
        refetch()
        if(retour){
          navigate(-1)
        }
      } catch (error) {
        console.error('Requête échouée:', error);
        toast.error(`Erreur: ${error.data?.message}` || "Echec d'ajout de dépense");
      }
    } 
  };

  
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{ borderRadius: '20px' }}
          >
            <h2 className="modal-header">IMPORTER VOS FICHIERS SALARIES</h2>
            <hr />
            <div className="container-background w-100 w-md-75 px-0 px-md-5">
              <Container fluid className="container-style p-4 mb-5">
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="d-flex flex-column align-items-center gap-5">
                    <Row>
                      <Form.Group controlId="fileUploadControl" className="w-100">
                        <FileUpload onFileSelect={handleFileSelect} />
                      </Form.Group>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={12} className="text-center">
                        <div className="d-flex gap-3">
                          <button
                            onClick={() => onClose()}
                            className="btn btn-danger rounded rounded-pill px-4"
                          >
                            Annuler
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary rounded rounded-pill px-4"
                            style={{
                              backgroundColor: '#068f7a',
                              borderColor: '#068f7a',
                            }}
                            disabled={
                              !selected
                            } // Add other fields as necessary
                          >
                            Enregistrer
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Container>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default ModalExcel;
